import { Link, graphql } from 'gatsby'
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing'
import * as Contentstyle from '../../components/content/content.module.scss'
import * as style from '../../components/album/album.module.scss'
import Content from '../../components/content/content'
import Img from "gatsby-image"
import React from 'react'

const SheSaidEP = ( {data} ) => (
  <ModalRoutingContext.Consumer>
    {({ modal, closeTo }) => (
      <div className={style.albumDetails}>
        {modal ? (
          <Link to={closeTo}>
            <svg className={style.albumDetails__close} width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.53553 0.464768C7.92606 0.855293 7.92606 1.48846 7.53553 1.87898L5.41421 4.0003L7.53553 6.12162C7.92606 6.51215 7.92606 7.14531 7.53553 7.53584C7.14501 7.92636 6.51184 7.92636 6.12132 7.53584L4 5.41452L1.87868 7.53584C1.48815 7.92636 0.854988 7.92636 0.464463 7.53584C0.0739387 7.14531 0.0739387 6.51215 0.464463 6.12162L2.58578 4.0003L0.464463 1.87898C0.073939 1.48846 0.0739387 0.855293 0.464463 0.464768C0.854988 0.0742437 1.48815 0.0742441 1.87868 0.464768L4 2.58609L6.12132 0.464768C6.51184 0.0742441 7.14501 0.0742437 7.53553 0.464768Z" />
            </svg>
          </Link>
        ) : (
          <div>
            {/* Not sure */}
          </div>
        )}

          <Content extraClass='content--modal'>
            <div className="column column--50">
              <div className="sticky">

            <Img className={style.albumDetails__cover} fluid={data.cover.childImageSharp.fluid} />
            {/* 
            <p className={style.albumDetails__credits}>
            <strong>Executive Producer:</strong> Specyal T<br/>
            <strong>Label:</strong> Soul Kiss Ent. <br/>
            <strong>Recorded At:</strong> Phase One Studios<br/>
            <strong>Production Provided By:</strong> Ted Chin & Soul Kiss Ent.<br/>
            <strong>Mixing &amp; Mastering Provided By:</strong> Soul Kiss Ent. 
            </p>
             */}

            <div className={Contentstyle.subtitleLowercase}>Tracks</div>

            <ol className={style.albumDetails__tracks}>
              <li>You Again</li>
              <li>What Can I Say?! </li>
              <li>Satan's Secretary</li>
              <li>Cold Feet</li>
              <li>I Remember</li>
              <li>Step To Me ft. Catherine Marie (original version)</li>
            </ol>

            </div>
            </div>
            <div className="column column--50">

            <div className={Contentstyle.subtitleLowercase}>Track Synopsis</div>

              <p><strong>You Again </strong><br/>
              Caught up in a verbally abusive relationship, this woman finally is able to free herself from it all. After a long time has passed, she accidentally runs into her ex and wonders if she'll revert back to her old self now in his presence.</p>
              <hr/>
              <p><strong>What Can I Say?! </strong><br/>
              After 20 years of being happily married, this woman learns she is dying of cancer and has to be her husband's strength in her final weeks.</p>
              <hr/>
              <p><strong>Satan's Secretary </strong><br/>
              Fresh out of University, this young naive woman takes her first job as a secretary to a married high powered business man. He quickly seduces her. Embarrassed and humiliated she writes a letter to his wife explaining their torrid affair.</p>
              <hr/>
              <p><strong>Cold Feet </strong><br/>
              One very adventurous woman begins a relationship with a man who she quickly finds out is her complete opposite. Annoyed she leaves him, then 2 years later coincidentally runs into him with his new woman at an ironic location.</p>
              <hr/>
              <p><strong>I Remember</strong><br/>
              As she prepares to say goodbye to her best friend, this woman wonders if she has the strength to actually make it through the ceremony.</p>
              <hr/>
              <p><strong>Step To Me ft. Catherine Marie (original version)</strong><br/>
              A female nemesis of a female recording artist who is her self proclaimed #1 hater is finally put in her place verbally!</p>

              </div>
                       
         

          </Content>

      </div>
    )}
  </ModalRoutingContext.Consumer>
)

export default SheSaidEP


export const query = graphql`
query {
  cover: file(relativePath: {eq: "st-store/albums/she-said.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
       } 
    }
  }
}
`